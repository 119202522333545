// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?&family=Secular+One&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  scroll-behavior: smooth;
}

:root{
  --lighter: linear-gradient(159deg, rgb(45, 45, 58) 0%, rgb(43, 43, 53) 100%);
  ---background: #191923;
}

body{
  width: 100%;
  background-color: var(---background);
  color: #fff;

}

.green {
  color: #01be96;
}

.slick-list, .slick-slider, .slick-track{
  padding: 1.5rem 0;
}

.center .slick-center .project{
  transform: scale(1.3);
  z-index: 10000;
  transition: all 400ms ease-in-out;
  opacity: 1;
  pointer-events: all;

}

.center .project{
  opacity: 0.7;
  pointer-events: none;
}

@media(max-width: 990px){
  .center .slick-center .project{
    transform: scale(1);

    .center .project{
    opacity: 1;
    pointer-events: all;
    }
  }}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAGA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,qCAAqC;EACrC,uBAAuB;AACzB;;AAEA;EACE,4EAA4E;EAC5E,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,oCAAoC;EACpC,WAAW;;AAEb;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;EACrB,cAAc;EACd,iCAAiC;EACjC,UAAU;EACV,mBAAmB;;AAErB;;AAEA;EACE,YAAY;EACZ,oBAAoB;AACtB;;AAEA;EACE;IACE,mBAAmB;;IAEnB;IACA,UAAU;IACV,mBAAmB;IACnB;EACF,CAAC","sourcesContent":["@import url('https://fonts.googleapis.com/css2?&family=Secular+One&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap');\n\n*{\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  font-family: 'Montserrat', sans-serif;\n  scroll-behavior: smooth;\n}\n\n:root{\n  --lighter: linear-gradient(159deg, rgb(45, 45, 58) 0%, rgb(43, 43, 53) 100%);\n  ---background: #191923;\n}\n\nbody{\n  width: 100%;\n  background-color: var(---background);\n  color: #fff;\n\n}\n\n.green {\n  color: #01be96;\n}\n\n.slick-list, .slick-slider, .slick-track{\n  padding: 1.5rem 0;\n}\n\n.center .slick-center .project{\n  transform: scale(1.3);\n  z-index: 10000;\n  transition: all 400ms ease-in-out;\n  opacity: 1;\n  pointer-events: all;\n\n}\n\n.center .project{\n  opacity: 0.7;\n  pointer-events: none;\n}\n\n@media(max-width: 990px){\n  .center .slick-center .project{\n    transform: scale(1);\n\n    .center .project{\n    opacity: 1;\n    pointer-events: all;\n    }\n  }}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
